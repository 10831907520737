body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 20px;
  line-height: 24px;
  color: #131414;
  font-family: "Garnett";
  text-align: center;
  margin-bottom: -50px;
}

.loader {
  width: 100%;
  max-width: 375px;
}
